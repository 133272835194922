import * as React from 'react'
import createPersistedState from 'use-persisted-state'
import RequiresAuthorisationSection from 'components/RequiresAuthorisationSection'
import ScheduledTasksListContainer from './ScheduledTasksListContainer'
import { Checkbox, FormControlLabel, Grid, Fade } from '@mui/material'
import { IsShowingCompletedTasksContext } from './useIsShowingCompletedTasks'
import { timezone } from 'formsHostnameConfiguration'
import { toDate } from 'date-fns-tz'
import { getDateString } from 'hooks/useScheduledTasks'
import useIsAuthorised from 'hooks/useIsAuthorised'

const useShowCompletedTasksState = createPersistedState<boolean>(
  'SHOW_COMPLETED_TASKS',
)

function ScheduledTasksScene({
  title,
  taskGroupInstanceId,
}: {
  title: string
  taskGroupInstanceId: string | undefined
}) {
  const isAuthorised = useIsAuthorised()

  const [date, setDate] = React.useState(() => {
    return toDate(new Date(), {
      timeZone: timezone,
    })
  })

  const isShowingCompletedTasksToggle = React.useMemo(() => {
    return (
      (isAuthorised === null || isAuthorised) &&
      getDateString(date) === getDateString(new Date())
    )
  }, [date, isAuthorised])

  const [isShowingCompletedTasks, setIsShowingCompletedTasks] =
    useShowCompletedTasksState(false)

  return (
    <div className="ob-scheduled-tasks section is-mobile-section">
      <div className="container">
        <Grid
          className="ob-header has-margin-bottom-4"
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs>
            <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
              {title}
            </h1>
          </Grid>
          <Fade in={isShowingCompletedTasksToggle} appear={false}>
            <Grid item xs={false}>
              <FormControlLabel
                sx={{ marginRight: 0 }}
                labelPlacement="end"
                control={<Checkbox />}
                checked={!!isShowingCompletedTasks}
                label="Show completed tasks"
                onChange={(event, checked) => {
                  setIsShowingCompletedTasks(checked)
                }}
                className="cypress-show-completed-tasks-label ob-label__show-completed-tasks"
              />
            </Grid>
          </Fade>
        </Grid>
        <RequiresAuthorisationSection label={title}>
          <IsShowingCompletedTasksContext.Provider
            value={isShowingCompletedTasks}
          >
            <ScheduledTasksListContainer
              taskGroupInstanceId={taskGroupInstanceId}
              date={date}
              setDate={setDate}
            />
          </IsShowingCompletedTasksContext.Provider>
        </RequiresAuthorisationSection>
      </div>
    </div>
  )
}

export default React.memo(ScheduledTasksScene)
