import { FormsAppsTypes } from '@oneblink/types'

const formsHostnameConfiguration =
  'formsHostnameConfiguration' in window && window.formsHostnameConfiguration
    ? (window.formsHostnameConfiguration as FormsAppsTypes.FormsAppConfiguration<FormsAppsTypes.FormsListStyles>)
    : null

export const timezone =
  formsHostnameConfiguration?.tz ||
  Intl.DateTimeFormat().resolvedOptions().timeZone

export default formsHostnameConfiguration
