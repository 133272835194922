import { FormsAppsTypes } from '@oneblink/types'
import { RequiresAuthorisationSection } from 'components'
import * as React from 'react'
import CPHCMSContentItemsList from './CPHCMSContentItemsList'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

function CPHCMSContentInstanceScene({
  menuItem,
}: {
  menuItem: FormsAppsTypes.FormsAppCPHCMSContentMenuItem
}) {
  return (
    <div className="ob-hcms-content section is-mobile-section">
      <div className="container">
        <div className="ob-header has-margin-bottom-4">
          <h1 className="title is-1 is-size-3-mobile ob-header__heading is-marginless">
            {menuItem.label}
          </h1>
          <RequiresAuthorisationSection label={menuItem.label}>
            <div className="has-margin-top-5">
              <CPHCMSContentItemsList
                menuItem={menuItem}
                formsAppId={formsHostnameConfiguration?.formsAppId || NaN}
                formsAppEnvironmentId={
                  formsHostnameConfiguration?.formsAppEnvironmentId || NaN
                }
              />
            </div>
          </RequiresAuthorisationSection>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CPHCMSContentInstanceScene)
