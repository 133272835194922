import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { OneBlinkReadOnlyForm } from '@oneblink/apps-react'
import ErrorMessage from '@oneblink/apps-react/dist/components/messages/ErrorMessage'
import { OnLoading } from 'components'
import formsHostnameConfiguration from 'formsHostnameConfiguration'
import useFormSubmissionData from 'hooks/useFormSubmissionData'
import * as React from 'react'

type Props = {
  isOpen: boolean
  onClose: (isOpen: false) => void
  formId: number
  submissionId: string
}

function RenderForm(props: { formId: number; submissionId: string }) {
  const [state, onTryAgain] = useFormSubmissionData(props)

  if (state.status === 'LOADING') {
    return (
      <div className="cypress-loading has-text-centered">
        <OnLoading className="has-text-centered" />
      </div>
    )
  }
  if (state.status === 'ERROR') {
    return (
      <ErrorMessage title="Error Fetching Submission" onTryAgain={onTryAgain}>
        <span className="cypress-loading-auto-save-error">
          {state.error.message}
        </span>
      </ErrorMessage>
    )
  }

  const { definition, submission, task, taskGroup, taskGroupInstance } =
    state.result

  return (
    <div
      className={definition?.customCssClasses?.join(' ') ?? ''}
      id={`form-${definition.id}`}
    >
      <OneBlinkReadOnlyForm
        form={definition}
        initialSubmission={submission}
        task={task}
        taskGroup={taskGroup}
        taskGroupInstance={taskGroupInstance}
        googleMapsApiKey={formsHostnameConfiguration?.googleMapsApiKey}
        primaryColour={formsHostnameConfiguration?.styles?.highlightColour}
      />
    </div>
  )
}

export default function SubmissionDataModal({
  isOpen,
  onClose,
  formId,
  submissionId,
}: Props) {
  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>Form Submission</DialogTitle>
      <DialogContent dividers>
        <RenderForm formId={formId} submissionId={submissionId} />
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="button ob-button is-primary"
          onClick={() => onClose(false)}
          autoFocus
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  )
}
