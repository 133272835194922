import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { authService } from '@oneblink/apps'

import { OnLoading, ErrorModal } from 'components'
import { LOGOUT_CONTINUE_TO } from './SignOutScene'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const logoutRedirectUrl = formsHostnameConfiguration?.logoutRedirectUrl

function LogoutScene() {
  const history = useHistory()
  const [error, setError] = React.useState<Error | null>(null)
  const redirect = React.useCallback(() => {
    const continueTo = localStorage.getItem(LOGOUT_CONTINUE_TO)
    if (continueTo) {
      localStorage.removeItem(LOGOUT_CONTINUE_TO)
      window.location.replace(continueTo)
    } else {
      history.replace('/')
    }
  }, [history])

  React.useEffect(() => {
    const abortController = new AbortController()
    const run = async () => {
      try {
        await authService.logout()
        if (logoutRedirectUrl) {
          window.location.replace(logoutRedirectUrl)
        } else {
          redirect()
        }
      } catch (error) {
        console.warn('Error logging out', error)
        if (!abortController.signal.aborted) {
          setError(error as Error)
        }
      }
    }

    run()

    return () => {
      abortController.abort()
    }
  }, [redirect])

  return (
    <section className="section ob-authentication">
      <ErrorModal error={error} onClose={redirect} />
      {!error && (
        <div className="cypress-loading has-text-centered">
          <OnLoading className="has-text-centered" />
          <span>Logging Out</span>
        </div>
      )}
    </section>
  )
}

export default React.memo(LogoutScene)
