import * as React from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import ErrorMessage from '@oneblink/apps-react/dist/components/messages/ErrorMessage'

import {
  scheduledTaskGroupsLabel,
  scheduledTasksGroupsHref,
} from 'services/menu-items-service'
import useScheduledTaskGroupInstances from 'hooks/useScheduledTaskGroupInstances'
import { OnLoading } from 'components'
import { Chip } from '@mui/material'
import { Clickable } from 'components/Clickable'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const formsAppId = formsHostnameConfiguration?.formsAppId || NaN

function ScheduledTaskGroupInstancesList() {
  const history = useHistory()

  const handleSelectTaskGroupsInstance = React.useCallback(
    (taskGroupsInstanceId: string) => {
      history.push(`${scheduledTasksGroupsHref}/${taskGroupsInstanceId}`)
    },
    [history],
  )

  const [scheduledTaskGroupInstancesState, refreshScheduledTaskGroupInstances] =
    useScheduledTaskGroupInstances(formsAppId)

  if (scheduledTaskGroupInstancesState.status === 'LOADING')
    return (
      <div className="cypress-loading has-text-centered">
        <OnLoading className="has-text-centered" />
        <span>Retrieving tasks...</span>
      </div>
    )

  if (scheduledTaskGroupInstancesState.status === 'ERROR') {
    return (
      <ErrorMessage
        title="Error Loading Tasks"
        onTryAgain={refreshScheduledTaskGroupInstances}
      >
        <span className="cypress-scheduled-task-group-load-error-message">
          {scheduledTaskGroupInstancesState.error.message}
        </span>
      </ErrorMessage>
    )
  }

  if (scheduledTaskGroupInstancesState.result.taskGroupInstances.length) {
    return (
      <>
        <div className="has-margin-bottom-6">
          {scheduledTaskGroupInstancesState.result.taskGroupInstances.map(
            ({
              taskGroupInstanceId,
              label,
              taskAvailabilities: allTaskAvailabilities,
              taskGroup,
            }) => {
              const taskAvailabilities = allTaskAvailabilities.filter(
                ({ completedTask }) => !completedTask,
              )
              const overdueTasks = taskAvailabilities.filter(
                (t) => t.daysAvailable > 0,
              )

              return (
                <div
                  className="has-margin-bottom-8 is-raised-1 has-thick-border-left is-primary-thick-border-left"
                  key={taskGroupInstanceId}
                >
                  <Clickable
                    className={clsx(
                      'ob-list__item ob-scheduled-task-groups__list-item cypress-scheduled-task-groups-list-item',
                    )}
                    onClick={() => {
                      handleSelectTaskGroupsInstance(taskGroupInstanceId)
                    }}
                  >
                    <div className="ob-list__content-wrapper ob-scheduled-task-groups__list-item-content-wrapper">
                      <div className="ob-list__content">
                        <div className="ob-list__text-primary">{label}</div>
                        <div className="ob-list__text-secondary">
                          {taskGroup.name}
                        </div>
                      </div>
                    </div>
                    <div className="ob-scheduled-task-groups__details-wrapper">
                      {!!overdueTasks.length && (
                        <Chip
                          className="ob-scheduled-task-groups__overdue-tasks"
                          label={`${overdueTasks.length} task${
                            overdueTasks.length > 1 ? 's' : ''
                          } overdue`}
                          size="small"
                          color="secondary"
                        />
                      )}
                      <Chip
                        className="ob-scheduled-task-groups__remaining-tasks"
                        label={`${taskAvailabilities.length} task${
                          taskAvailabilities.length > 1 ? 's' : ''
                        } remaining`}
                        size="small"
                        color="primary"
                      />
                    </div>
                  </Clickable>
                </div>
              )
            },
          )}
        </div>
      </>
    )
  }

  return (
    <span className="cypress-no-scheduled-task-groups ob-scheduled-task-groups__no-tasks">
      You have no {scheduledTaskGroupsLabel}.
    </span>
  )
}

export default React.memo(ScheduledTaskGroupInstancesList)
