import * as React from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { determineIsActive } from 'components/bottom-navigation'
import { menuItems } from 'services/menu-items-service'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const formsAppName = formsHostnameConfiguration?.name

function PageTitleHandler() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    const isFormRoute = !!matchPath(pathname, {
      path: '/forms/:id',
      strict: true,
    })

    if (isFormRoute) {
      // page titles for forms are handled in the form loader
      return
    }

    const activeMenuItem = menuItems.find((menuItem) =>
      determineIsActive(menuItem, pathname),
    )
    if (!activeMenuItem) {
      switch (pathname) {
        case '/sign-up':
          document.title = 'Sign Up'
          break
        case '/login':
          document.title = 'Log In'
          break
        case '/payment-form':
          document.title = 'Make a Payment'
          break
        case '/not-found':
          document.title = 'Not Found'
          break
        default:
          document.title = formsAppName || 'Home'
          break
      }
    } else if (
      activeMenuItem?.label &&
      document.title !== activeMenuItem.label
    ) {
      document.title = activeMenuItem.label
    }
  }, [pathname])

  return null
}

export default React.memo(PageTitleHandler)
