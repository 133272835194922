import { FormTypes, SubmissionTypes } from '@oneblink/types'
import { OneBlinkAppsError } from '@oneblink/apps'
import serverRequest from './serverRequest'

export default async function runPersonalisation(
  personalisation: FormTypes.Form['personalisation'],
  payload: {
    formsAppId: number | null
    formId: number
    externalIdUrlSearchParam: string | null
    draftId: string | null
    preFillFormDataId: string | null
    jobId: string | null
    previousFormSubmissionApprovalId: string | null
  },
  abortSignal?: AbortSignal,
): Promise<{
  submission?: SubmissionTypes.S3SubmissionData['submission']
  elements?: FormTypes.FormElement[]
}> {
  const result = await serverRequest(personalisation, payload, abortSignal)
  if (!result) {
    return {}
  }
  const { url, response } = result

  if (!response.ok) {
    const data = await response.text()
    console.log(
      'Response from the personalisation endpoint:',
      url,
      response.status,
      data,
    )
    let json: ReturnType<typeof JSON.parse>
    try {
      json = JSON.parse(data)
    } catch (err) {
      // Nothing
    }
    if (response.status === 400 && json && json.message) {
      throw new OneBlinkAppsError(json.message, {
        httpStatusCode: response.status,
      })
    }
    throw new OneBlinkAppsError(
      'Personalisation failed. We received an invalid response from the personalisation url.',
      {
        originalError: new Error(data),
        httpStatusCode: response.status,
      },
    )
  }

  const data = await response.json()
  console.log('Personalisation result from URL', url, data)
  return data
}
