import * as React from 'react'
import { useAuth, useSignUp } from '@oneblink/apps-react'

import { ErrorModal } from 'components'
import clsx from 'clsx'
import { useSetIsAuthorised } from 'hooks/useIsAuthorised'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const formsAppId = formsHostnameConfiguration?.formsAppId || NaN

function RequestAccessSignUpButton({
  onSuccess,
  onError,
  onStart,
}: {
  onSuccess?: () => void
  onError?: () => void
  onStart?: () => void
}) {
  const { userProfile } = useAuth()

  const setIsAuthorised = useSetIsAuthorised()
  const handleSignUpSuccess = React.useCallback(() => {
    onSuccess?.()
    setIsAuthorised()
  }, [onSuccess, setIsAuthorised])

  const { isSigningUp, signUpWithUserDetails, signUpError, clearSignUpError } =
    useSignUp({
      formsAppId,
      username: userProfile?.username ?? '',
      firstName: userProfile?.firstName ?? '',
      lastName: userProfile?.lastName ?? '',
      onSuccess: handleSignUpSuccess,
      onError,
    })

  const handleRequestAccess = React.useCallback(() => {
    if (!userProfile) {
      console.error(
        'Cannot sign up with single click button if user is not logged in. This is a developer error, and this component should not be rendered unless the user is logged in.',
      )
      return
    }
    onStart?.()
    signUpWithUserDetails()
  }, [userProfile, onStart, signUpWithUserDetails])

  return (
    <>
      <button
        type="button"
        className={clsx(
          'button ob-button is-primary ob-button__request-access-sign-up cypress-request-access-sign-up-button',
          {
            'is-loading': isSigningUp,
          },
        )}
        disabled={isSigningUp}
        onClick={handleRequestAccess}
      >
        Sign Up
      </button>

      <ErrorModal error={signUpError} onClose={clearSignUpError} />
    </>
  )
}

export default React.memo(RequestAccessSignUpButton)
