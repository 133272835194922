import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '@oneblink/apps-react'

import useQuery from 'hooks/useQuery'
import SignUp from 'authentication/SignUp'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const pwaSettings = formsHostnameConfiguration?.pwaSettings || null

function SignUpScene() {
  const query = useQuery()
  const { isLoggedIn } = useAuth()

  if (isLoggedIn) {
    return (
      <Redirect
        to={typeof query.continueTo === 'string' ? query.continueTo : '/'}
      />
    )
  }

  return (
    <div className="section is-mobile-section ob-login">
      <div className="container">
        {!!pwaSettings && !!pwaSettings.homeScreenIconUrl && (
          <figure className="ob-login__figure image image is-128x128 has-margin-bottom-6">
            <img
              alt="Application Icon"
              className="is-rounded"
              src={pwaSettings.homeScreenIconUrl}
            />
          </figure>
        )}
        <SignUp />
      </div>
    </div>
  )
}

export default React.memo(SignUpScene)
