import { OneBlinkAppsError, formService } from '@oneblink/apps'
import { FormTypes } from '@oneblink/types'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

const formsAppId = formsHostnameConfiguration?.formsAppId || undefined
const formsAppEnvironmentId =
  formsHostnameConfiguration?.formsAppEnvironmentId || undefined
const organisationId = formsHostnameConfiguration?.organisationId || 'UNKNOWN'

async function getForms(abortSignal: AbortSignal): Promise<FormTypes.Form[]> {
  return formService.getForms(formsAppId || NaN, abortSignal)
}

async function getForm({
  formId,
  formSlug,
  abortSignal,
}: {
  formId: number | null
  formSlug: string | null
  abortSignal: AbortSignal
}): Promise<FormTypes.Form> {
  const form = await formService.getForm({
    formId: formId || undefined,
    formSlug: formSlug || undefined,
    formsAppEnvironmentId,
    formsAppId,
    abortSignal,
  })
  if (
    form.organisationId === organisationId &&
    form.formsAppEnvironmentId === formsAppEnvironmentId
  ) {
    return form
  }
  console.warn(
    'Form does not belong to the organisation and environment associated with the current forms app',
    {
      formsHostnameConfiguration,
      form,
    },
  )
  throw new OneBlinkAppsError(
    'We could not find the form you are looking for. Please contact your administrator to ensure your form configuration has been completed successfully.',
    {
      title: 'Unknown Form',
    },
  )
}

export default {
  getForms,
  getForm,
}
