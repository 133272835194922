import * as React from 'react'
import { useLogin } from '@oneblink/apps-react'

import useQuery from 'hooks/useQuery'
import clsx from 'clsx'
import { ErrorModal, Input, MaterialIcon } from 'components'
import LoginWithGoogle from './LoginWithGoogle'
import formsHostnameConfiguration from 'formsHostnameConfiguration'

type Props = {
  isGoogleLoginSupported: boolean
}

function Login({ isGoogleLoginSupported }: Props) {
  const query = useQuery()
  const [username, setUsername] = React.useState(
    typeof query.username === 'string' ? query.username : '',
  )
  const [password, setPassword] = React.useState('')
  const [newPasswordConfirmed, setNewPasswordConfirmed] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [code, setCode] = React.useState('')

  const {
    // Login
    loginWithGoogle,
    loginWithUsernamePassword,
    isLoggingIn,
    loginError,
    clearLoginError,
    // MFA
    isMfaCodeRequired,
    isSubmittingMfaCode,
    submitMfaCode,
    // Reset Temp Password
    isPasswordTemporary,
    isResettingTemporaryPassword,
    resetTemporaryPassword,
    // Showing Forgot Password
    isShowingForgotPassword,
    showForgotPassword,
    hideForgotPassword,
    forgotPasswordError,
    clearForgotPasswordError,
    // Sending Forgot Password Code
    isSendingForgotPasswordCode,
    sendForgotPasswordCode,
    // Resetting Forgotten Password
    hasSentForgotPasswordCode,
    isResettingForgottenPassword,
    resetForgottenPassword,
    // Validation
    usernameValidation,
    passwordValidation,
    codeValidation,
    newPasswordValidation,
    newPasswordConfirmedValidation,
  } = useLogin({
    username,
    password,
    newPassword,
    newPasswordConfirmed,
    code,
    formsAppId: formsHostnameConfiguration?.formsAppId || NaN,
  })

  if (hasSentForgotPasswordCode) {
    return (
      <Form
        onSubmit={resetForgottenPassword}
        label="We have sent you a password reset code via email. Enter it below to reset your password."
      >
        <Input
          name="code"
          type="password"
          label="Code"
          value={code}
          onChange={setCode}
          isInvalid={codeValidation.isInvalid}
          icon="code"
          autoComplete="one-time-code"
        />

        <Input
          name="newPassword"
          type="password"
          label="New Password"
          value={newPassword}
          onChange={setNewPassword}
          isInvalid={newPasswordValidation.isInvalid}
          icon="lock"
          autoComplete="new-password"
        />

        <Input
          name="newPasswordConfirmed"
          type="password"
          label="Confirm Password"
          value={newPasswordConfirmed}
          onChange={setNewPasswordConfirmed}
          isInvalid={newPasswordConfirmedValidation.isInvalid}
          icon="lock"
          autoComplete="new-password"
        />

        <SubmitButton
          isLoading={isResettingForgottenPassword}
          isInvalid={
            codeValidation.isInvalid ||
            newPasswordValidation.isInvalid ||
            newPasswordConfirmedValidation.isInvalid
          }
        >
          Change Password
        </SubmitButton>

        <PasswordValidationMessages validation={newPasswordValidation} />

        <ErrorModal
          error={forgotPasswordError}
          onClose={clearForgotPasswordError}
        />
      </Form>
    )
  }

  if (isShowingForgotPassword) {
    return (
      <Form
        onSubmit={sendForgotPasswordCode}
        label="Enter your email address and we will send you a code to reset your password."
      >
        <div>
          <Input
            name="username"
            type="email"
            label="Email Address"
            value={username}
            onChange={setUsername}
            isInvalid={null}
            icon="email"
            autoComplete="username"
          />
        </div>

        <ForgotPasswordLink onClick={hideForgotPassword}>
          Remembered your password?
        </ForgotPasswordLink>

        <SubmitButton
          isLoading={isSendingForgotPasswordCode}
          isInvalid={usernameValidation.isInvalid}
        >
          Reset Password
        </SubmitButton>

        <ErrorModal
          error={forgotPasswordError}
          onClose={clearForgotPasswordError}
        />
      </Form>
    )
  }

  if (isPasswordTemporary) {
    return (
      <Form
        onSubmit={resetTemporaryPassword}
        label="The password you entered was only temporary and must be reset for security purposes. Please enter your new password below to continue."
      >
        <Input
          name="newPassword"
          type="password"
          label="New Password"
          value={newPassword}
          onChange={setNewPassword}
          isInvalid={newPasswordValidation.isInvalid}
          icon="lock"
          autoComplete="new-password"
        />

        <Input
          name="newPasswordConfirmed"
          type="password"
          label="Confirm Password"
          value={newPasswordConfirmed}
          onChange={setNewPasswordConfirmed}
          isInvalid={newPasswordConfirmedValidation.isInvalid}
          icon="lock"
          autoComplete="new-password"
        />

        <SubmitButton
          isLoading={isResettingTemporaryPassword}
          isInvalid={
            newPasswordValidation.isInvalid ||
            newPasswordConfirmedValidation.isInvalid
          }
        >
          Change Password &amp; Sign In
        </SubmitButton>

        <PasswordValidationMessages validation={newPasswordValidation} />

        <ErrorModal error={loginError} onClose={clearLoginError} />
      </Form>
    )
  }

  if (isMfaCodeRequired) {
    return (
      <Form
        onSubmit={submitMfaCode}
        label="Enter the 6-digit code found in your authenticator app"
      >
        <Input
          name="code"
          type="text"
          label="Code"
          value={code}
          onChange={setCode}
          isInvalid={codeValidation.isInvalid}
          icon="code"
          autoComplete="one-time-code"
        />

        <SubmitButton
          isLoading={isSubmittingMfaCode}
          isInvalid={codeValidation.isInvalid}
        >
          Sign In
        </SubmitButton>

        <ErrorModal error={loginError} onClose={clearLoginError} />
      </Form>
    )
  }

  return (
    <Form
      onSubmit={loginWithUsernamePassword}
      label="Sign in with your email address and password."
    >
      <div>
        <Input
          name="username"
          type="email"
          label="Email Address"
          value={username}
          onChange={setUsername}
          isInvalid={null}
          icon="email"
          data-cypress="username-input"
          autoComplete="username"
        />

        <Input
          name="password"
          type="password"
          label="Password"
          value={password}
          onChange={setPassword}
          isInvalid={null}
          icon="lock"
          data-cypress="password-input"
          autoComplete="current-password"
        />
      </div>

      <ForgotPasswordLink onClick={showForgotPassword}>
        Forgot your password?
      </ForgotPasswordLink>

      <SubmitButton
        isLoading={isLoggingIn}
        isInvalid={usernameValidation.isInvalid || passwordValidation.isInvalid}
      >
        Log In
      </SubmitButton>

      {!!isGoogleLoginSupported && (
        <>
          <div className="is-relative">
            <hr />
            <p className="ob-login__or">or</p>
          </div>

          <LoginWithGoogle onClick={loginWithGoogle} />
        </>
      )}

      <ErrorModal error={loginError} onClose={clearLoginError} />
    </Form>
  )
}

export default React.memo<Props>(Login)

export const SubmitButton = React.memo(function SubmitButton({
  children,
  isInvalid,
  isLoading,
}: {
  children: React.ReactNode
  isInvalid: boolean
  isLoading: boolean
}) {
  return (
    <button
      type="submit"
      className={clsx(
        'button ob-button is-primary is-fullwidth ob-login__button',
        {
          'is-loading': isLoading,
        },
      )}
      disabled={isLoading || isInvalid}
    >
      {children}
    </button>
  )
})

export const Form = React.memo(function Form({
  children,
  label,
  onSubmit,
}: {
  children: React.ReactNode
  label: string
  onSubmit: () => void
}) {
  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault()
      onSubmit()
    },
    [onSubmit],
  )
  return (
    <form noValidate onSubmit={handleSubmit}>
      <label className="label ob-label has-text-centered">{label}</label>
      {children}
    </form>
  )
})

const ForgotPasswordLink = React.memo(function ForgotPasswordLink({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: () => void
}) {
  return (
    <p className="is-size-7 has-margin-top-8 has-margin-bottom-7">
      <a onClick={onClick}>{children}</a>
    </p>
  )
})

const PasswordValidationMessage = React.memo(
  function PasswordValidationMessage({
    isValid,
    children,
  }: {
    isValid: boolean
    children: React.ReactNode
  }) {
    return (
      <div className="ob-login__validation-check">
        <MaterialIcon
          className={clsx('is-size-5 ob-login__validation-check-icon', {
            'has-text-success': isValid,
            'has-text-warning': !isValid,
          })}
        >
          {isValid ? 'check' : 'warning'}
        </MaterialIcon>
        <div>{children}</div>
      </div>
    )
  },
)

const PasswordValidationMessages = React.memo(
  function PasswordValidationMessages({
    validation,
  }: {
    validation: {
      hasLowercaseLetter: boolean
      hasUpperCaseLetter: boolean
      hasNumber: boolean
      hasSpecialCharacter: boolean
      hasMinLength: boolean
      isInvalid: boolean
    }
  }) {
    return (
      <article className="message is-small is-info has-margin-top-7">
        <div className="message-header">
          <p>Password Requirements</p>
        </div>
        <div className="message-body">
          <PasswordValidationMessage isValid={validation.hasLowercaseLetter}>
            Contains a lowercase letter
          </PasswordValidationMessage>
          <PasswordValidationMessage isValid={validation.hasUpperCaseLetter}>
            Contains an upper case letter
          </PasswordValidationMessage>
          <PasswordValidationMessage isValid={validation.hasNumber}>
            Contains a number
          </PasswordValidationMessage>
          <PasswordValidationMessage isValid={validation.hasSpecialCharacter}>
            Contains a special character
          </PasswordValidationMessage>
          <PasswordValidationMessage isValid={validation.hasMinLength}>
            Contains at least 8 characters
          </PasswordValidationMessage>
        </div>
      </article>
    )
  },
)
