import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { formService, localisationService } from '@oneblink/apps'
import { formElementsService, submissionService } from '@oneblink/sdk-core'
import { FormsAppsTypes } from '@oneblink/types'
import clsx from 'clsx'
import { useMediaQuery, useTheme } from '@mui/material'
import useInfiniteScrollDataLoad from '@oneblink/apps-react/dist/hooks/useInfiniteScrollDataLoad'
import { useLoadDataState } from '@oneblink/apps-react'
import ErrorMessage from '@oneblink/apps-react/dist/components/messages/ErrorMessage'
import useFilteredDrafts from 'hooks/useFilteredDrafts'
import { DraftModal, MaterialIcon, OnLoading } from 'components'
import CPHCMSContentItemsTable from './CPHCMSContentItemsTable'
import CPHCMSContentItemsGrid from './CPHCMSContentItemsGrid'
import CPHCMSDeleteContentModal from './modals/CPHCMSDeleteContentModal'
import CPHCMSPublishContentModal from './modals/CPHCMSPublishContentModal'
import CPHCMSDraftContentModal from './modals/CPHCMSDraftContentModal'
import {
  CPHCMSContentItems,
  ATTRIBUTE_LABEL_MAP,
  SUBMISSION_JSON_PROP,
  ORIGINAL_EXTERNAL_ID_PROP,
} from './constants'
import { useIsMounted } from '@oneblink/apps-react'

function createParsedSearch(searchString: string) {
  return searchString.replace(/[^\s\w]/g, (c) => `\\\\${c}`)
}

function CPHCMSContentItemsList({
  formsAppId,
  formsAppEnvironmentId,
  menuItem,
}: {
  formsAppId: number
  formsAppEnvironmentId: number
  menuItem: FormsAppsTypes.FormsAppCPHCMSContentMenuItem
}) {
  const [searchIsDebouncing, setSearchIsDebouncing] = React.useState(false)
  const [selectedContentItem, setSelectedContentItem] =
    React.useState<formService.CivicPlusHCMSContentItem>()
  const [deleteState, setDeleteState] = React.useState<{
    isDeleting: boolean
    isConfirming: boolean
    error: Error | null
  }>({
    isDeleting: false,
    isConfirming: false,
    error: null,
  })
  const [publishState, setPublishState] = React.useState<{
    isPublishing: boolean
    isConfirming: boolean
    error: Error | null
  }>({
    isPublishing: false,
    isConfirming: false,
    error: null,
  })
  const [draftState, setDraftState] = React.useState<{
    isDrafting: boolean
    isConfirming: boolean
    error: Error | null
  }>({
    isDrafting: false,
    isConfirming: false,
    error: null,
  })
  const history = useHistory()
  const isMounted = useIsMounted()

  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const fetchForm = React.useCallback(
    (abortSignal: AbortSignal) => {
      return formService.getForm({
        formId: menuItem.formId,
        formSlug: undefined,
        formsAppId,
        formsAppEnvironmentId,
        abortSignal,
      })
    },
    [menuItem.formId, formsAppId, formsAppEnvironmentId],
  )

  const [formState, refreshFormState] = useLoadDataState(fetchForm)

  const {
    isLoading,
    loadError,
    records: contentItems,
    onTryAgain,
    filters,
    onChangeFilters,
    nextOffset,
    onRefresh,
    onReplace,
  } = useInfiniteScrollDataLoad<
    {
      $search?: string
      $filter?: string
      $orderby?: string
    },
    formService.CivicPlusHCMSContentItem
  >({
    isManual: true,
    limit: 50,
    onDefaultFilters: React.useCallback(() => {
      return {}
    }, []),
    onSearch: React.useCallback(
      async (currentParameters, paging, abortSignal) => {
        const result = await formService.searchCivicPlusHCMSContentItems({
          ...currentParameters,
          $search: currentParameters.$search
            ? `"${createParsedSearch(currentParameters.$search)}"`
            : undefined,
          formsAppId,
          formId: menuItem.formId,
          $top: paging.limit,
          $skip: paging.offset,
          abortSignal,
        })
        const nextOffset = paging.limit + paging.offset
        setSearchIsDebouncing(false)
        return {
          records: result.items,
          meta: {
            ...paging,
            nextOffset: nextOffset < result.total ? nextOffset : undefined,
          },
        }
      },
      [formsAppId, menuItem.formId],
    ),
    debounceSearchMs: 500,
  })

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      onChangeFilters((c) => {
        return {
          ...c,
          $search: value || undefined,
        }
      }, true)
      setSearchIsDebouncing(true)
    },
    [onChangeFilters],
  )

  const form = formState.status === 'SUCCESS' ? formState.result : undefined

  const listItems: CPHCMSContentItems | undefined = React.useMemo(() => {
    if (!contentItems) {
      return
    }

    const columns: string[] = []

    const rows = contentItems.map((item, contentIndex) => {
      const values = menuItem.listDisplayAttributes.map((displayAttribute) => {
        const submissionDefinition = item.data['submission-json-v1']?.definition

        switch (displayAttribute.type) {
          case 'CP_HCMS_CONTENT_META': {
            const attribute = displayAttribute.attribute
            const contentItemKey = attribute.replace(
              'meta.',
              '',
            ) as keyof typeof item

            if (contentIndex === 0) {
              columns.push(ATTRIBUTE_LABEL_MAP[attribute])
            }

            let value = item[contentItemKey]
            switch (attribute) {
              case 'meta.created': {
                if (typeof value === 'string') {
                  value = localisationService.formatDatetime(new Date(value))
                }
                break
              }
              case 'meta.lastModified': {
                if (typeof value === 'string') {
                  value = localisationService.formatDatetime(new Date(value))
                }
                break
              }
            }

            return String(value)
          }
          case 'FORM_ELEMENT': {
            if (!form) {
              return
            }
            const elementId = displayAttribute.formElementId

            const element = formElementsService.findFormElement(
              form.elements,
              (el) => el.id === elementId,
            )

            if (!element) {
              return
            }

            const submission = item.data[SUBMISSION_JSON_PROP]?.submission ?? {}

            const result = submissionService.getElementSubmissionValue({
              elementId,
              formElements: submissionDefinition?.elements ?? [],
              submission,
              formatDateTime: (value) =>
                localisationService.formatDatetime(new Date(value)),
              formatDate: (value) =>
                localisationService.formatDate(new Date(value)),
              formatTime: (value) =>
                localisationService.formatTime(new Date(value)),
              formatCurrency: (value) =>
                localisationService.formatCurrency(value),
              formatNumber: (value) => localisationService.formatNumber(value),
            })

            const label = 'label' in element ? element.label : element.name
            if (contentIndex === 0) {
              columns.push(label)
            }

            return result?.value ? String(result.value) : undefined
          }
          case 'SUBMISSION_META': {
            const attribute = displayAttribute.attribute

            if (contentIndex === 0) {
              columns.push(ATTRIBUTE_LABEL_MAP[attribute])
            }
            return item.data[SUBMISSION_JSON_PROP]?.[ORIGINAL_EXTERNAL_ID_PROP]
          }
          default: {
            const never: never = displayAttribute
            console.log('Unconsidered display attribute', never)
          }
        }
        return
      })
      return {
        contentItem: item,
        values,
      }
    })

    return {
      columns,
      rows,
    }
  }, [contentItems, menuItem.listDisplayAttributes, form])

  const {
    filteredDrafts,
    isSyncingAndReloadingDrafts,
    actions: { filterDrafts, unfilterDrafts },
  } = useFilteredDrafts()

  const generateSearchParams = React.useCallback(
    (contentItem: formService.CivicPlusHCMSContentItem | undefined) => {
      if (!contentItem) {
        return
      }
      const submissionData = contentItem.data[SUBMISSION_JSON_PROP]
      const editSearchParams = new URLSearchParams()
      editSearchParams.append('externalId', contentItem.id)
      if (submissionData) {
        editSearchParams.append(
          'preFillData',
          JSON.stringify(submissionData.submission),
        )
      }
      return editSearchParams
    },
    [],
  )

  const selectItem = React.useCallback(
    (contentItem: formService.CivicPlusHCMSContentItem) => {
      setSelectedContentItem(contentItem)
      filterDrafts({
        filter(draft) {
          return (
            draft.formId === menuItem.formId &&
            draft.externalId === contentItem.id
          )
        },
        ifNoDrafts() {
          const editSearchParams = generateSearchParams(contentItem)
          const search = `?${editSearchParams?.toString()}`
          history.push(`/forms/${menuItem.formId}${search}`)
        },
      })
    },
    [history, filterDrafts, menuItem.formId, generateSearchParams],
  )

  const deselectItem = React.useCallback(() => {
    setSelectedContentItem(undefined)
    unfilterDrafts()
  }, [unfilterDrafts])

  const confirmDelete = React.useCallback(
    (contentItem: formService.CivicPlusHCMSContentItem) => {
      setSelectedContentItem(contentItem)
      setDeleteState({ isDeleting: false, isConfirming: true, error: null })
    },
    [],
  )

  const clearDeleteError = React.useCallback(() => {
    setDeleteState({ isDeleting: false, isConfirming: false, error: null })
  }, [])

  const handleCancelDelete = React.useCallback(() => {
    deselectItem()
    setDeleteState({ isDeleting: false, isConfirming: false, error: null })
  }, [deselectItem])

  const handleDelete = React.useCallback(async () => {
    if (!isMounted) {
      return
    }
    if (!selectedContentItem) {
      //this should not happen
      setDeleteState({
        isConfirming: false,
        isDeleting: false,
        error: new Error('No content item selected'),
      })
      return
    }

    let newError: Error | null = null

    try {
      setDeleteState({
        isConfirming: true,
        isDeleting: true,
        error: null,
      })
      await formService.deleteCivicPlusHCMSContentItem({
        formsAppId,
        formId: menuItem.formId,
        contentId: selectedContentItem.id,
      })
    } catch (e) {
      newError = e as Error
    }
    if (isMounted) {
      setDeleteState({
        isConfirming: false,
        isDeleting: false,
        error: newError,
      })
      deselectItem()
      onRefresh()
    }
  }, [
    formsAppId,
    isMounted,
    menuItem.formId,
    selectedContentItem,
    onRefresh,
    deselectItem,
  ])

  const confirmPublish = React.useCallback(
    (contentItem: formService.CivicPlusHCMSContentItem) => {
      setSelectedContentItem(contentItem)
      setPublishState({ isConfirming: true, isPublishing: false, error: null })
    },
    [],
  )

  const clearPublishError = React.useCallback(() => {
    setPublishState({ isPublishing: false, isConfirming: false, error: null })
  }, [])

  const handleCancelPublish = React.useCallback(() => {
    deselectItem()
    setPublishState({ isPublishing: false, isConfirming: false, error: null })
  }, [deselectItem])

  const handlePublish = React.useCallback(async () => {
    if (!isMounted) {
      return
    }
    if (!selectedContentItem) {
      //this should not happen
      setPublishState({
        isConfirming: false,
        isPublishing: false,
        error: new Error('No content item selected'),
      })
      return
    }

    let newError: Error | null = null

    try {
      setPublishState({
        isConfirming: true,
        isPublishing: true,
        error: null,
      })
      await formService.publishHCMSContentItem({
        formsAppId,
        formId: menuItem.formId,
        contentId: selectedContentItem.id,
      })
      onReplace((record) => {
        if (record.id === selectedContentItem.id) {
          return {
            ...record,
            status: 'Published',
            lastModified: new Date().toISOString().replace('Z$', '.000Z'),
          }
        }
        return record
      })
    } catch (e) {
      newError = e as Error
    }
    if (isMounted) {
      setPublishState({
        isConfirming: false,
        isPublishing: false,
        error: newError,
      })
      deselectItem()
    }
  }, [
    formsAppId,
    isMounted,
    deselectItem,
    menuItem.formId,
    selectedContentItem,
    onReplace,
  ])

  const confirmDraft = React.useCallback(
    (contentItem: formService.CivicPlusHCMSContentItem) => {
      setSelectedContentItem(contentItem)
      setDraftState({ isConfirming: true, isDrafting: false, error: null })
    },
    [],
  )

  const clearDraftError = React.useCallback(() => {
    setDraftState({ isDrafting: false, isConfirming: false, error: null })
  }, [])

  const handleCancelDraft = React.useCallback(() => {
    deselectItem()
    setDraftState({ isDrafting: false, isConfirming: false, error: null })
  }, [deselectItem])

  const handleDraft = React.useCallback(async () => {
    if (!isMounted) {
      return
    }
    if (!selectedContentItem) {
      //this should not happen
      setDraftState({
        isConfirming: false,
        isDrafting: false,
        error: new Error('No content item selected'),
      })
      return
    }

    let newError: Error | null = null

    try {
      setDraftState({
        isConfirming: true,
        isDrafting: true,
        error: null,
      })
      await formService.draftHCMSContentItem({
        formsAppId,
        formId: menuItem.formId,
        contentId: selectedContentItem.id,
      })
      onReplace((record) => {
        if (record.id === selectedContentItem.id) {
          return {
            ...record,
            status: 'Draft',
            lastModified: new Date().toISOString().replace('Z$', '.000Z'),
          }
        }
        return record
      })
    } catch (e) {
      newError = e as Error
    }
    if (isMounted) {
      setDraftState({
        isConfirming: false,
        isDrafting: false,
        error: newError,
      })
      deselectItem()
    }
  }, [
    formsAppId,
    isMounted,
    deselectItem,
    menuItem.formId,
    onReplace,
    selectedContentItem,
  ])

  if (formState.status === 'LOADING') {
    return (
      <div className="has-margin-bottom-6">
        <div className="cypress-loading has-text-centered">
          <OnLoading className="has-text-centered" />
        </div>
      </div>
    )
  }

  if (formState.status === 'ERROR') {
    return (
      <div className="has-margin-bottom-6">
        <ErrorMessage
          title="Error Retrieving Content"
          gutterTop
          onTryAgain={refreshFormState}
        >
          <span className="cypress-form-load-error-message">
            {formState.error.message}
          </span>
        </ErrorMessage>
      </div>
    )
  }

  return (
    <>
      <div className="has-margin-bottom-6">
        <DraftModal
          isSyncing={isSyncingAndReloadingDrafts}
          draftsForForm={filteredDrafts}
          onCancel={deselectItem}
          generateSearchParams={() => generateSearchParams(selectedContentItem)}
          newLinkText="Edit current content"
        />
        <CPHCMSDeleteContentModal
          handleCancelDelete={handleCancelDelete}
          handleDelete={handleDelete}
          clearDeleteError={clearDeleteError}
          {...deleteState}
        />
        <CPHCMSPublishContentModal
          handleCancelPublish={handleCancelPublish}
          handlePublish={handlePublish}
          clearPublishError={clearPublishError}
          {...publishState}
        />
        <CPHCMSDraftContentModal
          handleCancelDraft={handleCancelDraft}
          handleDraft={handleDraft}
          clearDraftError={clearDraftError}
          {...draftState}
        />

        <div className="field has-addons">
          <div
            className={clsx('control has-icons-left is-expanded', {
              'is-loading': searchIsDebouncing,
            })}
          >
            <input
              className="input ob-cp-hcms__search"
              type="text"
              placeholder="Search..."
              onChange={(e) => handleChangeSearch(e.target.value)}
              value={filters.$search ?? ''}
            />
            <span className="icon is-small is-left">
              <MaterialIcon>search</MaterialIcon>
            </span>
          </div>
          <div className="control">
            <button
              className="button"
              disabled={!!isLoading || searchIsDebouncing}
              onClick={onRefresh}
            >
              <span className="icon">
                <MaterialIcon>sync</MaterialIcon>
              </span>
              <span>Refresh</span>
            </button>
          </div>
        </div>
      </div>
      {!listItems?.rows.length && !isLoading && !loadError && (
        <div className="cypress-no-hcms-content-items-tasks ob-cp-hcms-content-items__no-content-items has-text-centered content">
          <p>
            <MaterialIcon className="has-text-primary icon-x-large">
              {menuItem.icon}
            </MaterialIcon>
          </p>
          <p>There are no content items matching your filters.</p>
        </div>
      )}
      {!!listItems?.rows.length && (
        <>
          {!largeScreen && (
            <CPHCMSContentItemsGrid
              selectItem={selectItem}
              deleteItem={confirmDelete}
              publishItem={confirmPublish}
              draftItem={confirmDraft}
              contentItems={listItems}
            />
          )}
          {largeScreen && (
            <div className="table-container cypress-cp-hcms-content-items-list ">
              <CPHCMSContentItemsTable
                selectItem={selectItem}
                deleteItem={confirmDelete}
                publishItem={confirmPublish}
                draftItem={confirmDraft}
                contentItems={listItems}
              />
            </div>
          )}
        </>
      )}

      {isLoading === 'INITIAL' && (
        <div className="cypress-loading has-text-centered has-margin-top-5">
          <OnLoading className="has-text-centered" />
          <span>Loading content...</span>
        </div>
      )}

      {loadError && (
        <>
          <ErrorMessage
            title="Error Retrieving Content"
            gutterTop
            onTryAgain={onTryAgain}
          >
            <span className="cypress-content-items-load-error-message">
              {loadError.message}
            </span>
          </ErrorMessage>
        </>
      )}

      {!!nextOffset && !loadError && isLoading !== 'INITIAL' && (
        <div className="ob-cp-hcms-content-items__load-more-container">
          <button
            className={clsx(
              'button ob-button is-primary is-outlined ob-cp-hcms-content-items__load-more-button',
              {
                'is-loading': isLoading === 'MORE',
              },
            )}
            onClick={() => onTryAgain(nextOffset)}
          >
            <span className="icon">
              <MaterialIcon>read_more</MaterialIcon>
            </span>
            <span>Load More</span>
          </button>
        </div>
      )}
    </>
  )
}

export default React.memo(CPHCMSContentItemsList)
